





















import EditProgramComponent from "@/components/editprogramcomponent/EditProgramComponent";
export default EditProgramComponent;
