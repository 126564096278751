import { Vue, Component } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';

@Component
export default class EditProgramComponent extends Vue {
  private objScreenText: ScreenText = new ScreenText();
  readonly STYLE = APP_CONST.STYLE;

  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }

}